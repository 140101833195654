import axios from "axios";

export const sendFormData = async function (email: string, topic?: string, message?: string) {
  try {
    await axios.get(`https://docs.google.com/forms/d/e/1FAIpQLScD6mYLiu26ZYwl5g6qcTiWCd3y99jt0Pes21XOev12R477Ug/formResponse?usp=pp_url&entry.2133519838=${email}&entry.16792380=${topic || ''}&entry.127932922=${message || ''}`)
  } catch (e: any) {
    // Expected response is CORS error - the answer is submitted successfully anyway.
    if (e.message != 'Network Error') {
      console.error('Failed to submit form', e)
      throw e;
    }
  }
}