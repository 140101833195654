import { FC } from 'react';
import styles from './HintQuestion.module.scss'

export const HintQuestion: FC<{ text: string }> = ({ text }) => {

  return (
    <span className={styles.questionHint}>
      ?
      <div className={styles.hintPopover}>{text}</div>
    </span>
  );
};
