import {FC, PropsWithChildren} from 'react';
import styles from './TextBlock.module.scss';
import {clsIf, clss} from "../../src/utils";
import {HintQuestion} from "../HintQuestion/HintQuestion";

export const TextBlock: FC<PropsWithChildren<{ main?: boolean, titleRegular: string, titleBold: string, slogan?: string, description: any, hint?: string, sticker?: string, className?: string }>> = ({
  main,
  titleBold,
  titleRegular,
  slogan,
  description,
  hint,
  sticker,
  className,
  children,
}) => {
  return (<div className={clss(styles.TextBlock, className, clsIf(main, styles.main))}>
    <span className={styles.slogan}>
      {slogan}
      {hint ? <HintQuestion text={hint}/> : <></>}
    </span>
    {main ?
      <h1 className={clss(styles.header, clsIf(sticker, styles.withSticker))}>{titleRegular}<b>{titleBold}</b></h1>
      :
      <h2 className={clss(styles.header, clsIf(sticker, styles.withSticker))}>{titleRegular}<b>{titleBold}</b></h2>
    }
    {sticker && <div className={styles.sticker}>{sticker}</div>}
    <div className={styles.description}>{description}{children}</div>
    {/* TODO line breaks*/}
  </div>);
};
