import {FC, forwardRef, PropsWithChildren, RefObject, useState} from "react";
import {Screen} from "../Screen/Screen";
import styles from './CTAScreen.module.scss'
import {clsIf, clss, isEmail} from "../../src/utils";
import {TextBlock} from "../TextBlock/TextBlock";
import {ActionButton} from "../ActionButton/ActionButton";
import {ImageWithBlur} from "../ImageWIthBlur/ImageWithBlur";
import * as gtag from "../../src/gtag";
import {sendFormData} from "../../src/forms";
import {useSmoothScroll} from "../SmoothScroll/SmoothScroll";
import {LoginButton} from "../LoginButton/LoginButton";
import {UserDto} from "../../src/dto";

export type ButtonConfig = {
  label: string,
  href: string | RefObject<HTMLDivElement>,
  eventLabel: string,
  eventName?: string,
}

export type LoginButtonConfig = {
  onLogin: (error: boolean, user?: UserDto) => void,
  eventLabel: string,
}

export type InlineLeadFormConfig = {
  buttonLabel: string,
  eventLabel: string
}

export type BackgroundTextConfig = {
  text: any,
  className?: string,
}


const InlineLeadForm: FC<{config: InlineLeadFormConfig}> = ({config: {
  buttonLabel,
  eventLabel,
}}) => {
  const [inlineFormSubmitted, setInlineFormSubmitted] = useState(false);
  const [inlineSending, setInlineSending] = useState(false);
  const [inlineError, setInlineError] = useState(false);
  const [emailInlineHasError, setEmailInlineHasError] = useState(false);
  const [emailInline, setEmailInline] = useState("");

  async function submitInlineForm() {
    gtag.event({
      action: "submit_form",
      label: eventLabel,
      label2: "inline"
    })

    if (!isEmail(emailInline)) {
      setEmailInlineHasError(true)
      return;
    }

    setInlineFormSubmitted(true)
    setInlineSending(true);
    try {
      await sendFormData(emailInline, `${eventLabel}_inline`)
    } catch (e: any) {
      setInlineError(true)
    }
    setInlineSending(false)
  }

  return <div className={styles.inlineLeadForm}>{inlineFormSubmitted ?
    inlineSending ? <div className={styles.infoPlate}>...</div> : inlineError ? <div className={clss(styles.infoPlate, styles.error)}>Woops... Something went wrong. Please try again later, or write directly to <a href="mailto:welcome@satelstories.com">welcome@satelstories.com</a></div> :
      <div className={styles.infoPlate}>Ahoy! Thanks for reaching out, we’ll answer you as soon as possible</div>
    : <>
      <input className={clss(styles.input, clsIf(emailInlineHasError, styles.error))} placeholder={'my-email@example.com'} value={emailInline} onChange={(e) => {setEmailInline(e.target.value); setEmailInlineHasError(false)}}/>
      <ActionButton label={buttonLabel} onClick={submitInlineForm} size={'large'}/>
    </>}</div>
}

export const CTAScreen = forwardRef<HTMLDivElement, PropsWithChildren<{
  titleRegular: string,
  titleBold: string,
  description: any,
  slogan?: string,
  hint?: string,
  button?: ButtonConfig,
  inlineForm?: InlineLeadFormConfig,
  loginButton?: LoginButtonConfig,
  imageSrc?: any,
  imageAlt?: string,
  imageHiddenOnMobile?: boolean,
  right?: boolean,
  gray?: boolean,
  backgroundText?: BackgroundTextConfig,
}>>(({
  titleRegular,
  titleBold,
  description,
  slogan,
  hint,
  button,
  loginButton,
  inlineForm,
  imageSrc,
  imageAlt,
  imageHiddenOnMobile,
  right,
  gray,
  backgroundText,
  children,
}, ref) => {
  const buttonSize = "large";

  if (button && inlineForm || button && loginButton || inlineForm && loginButton) {
    throw new Error('Should use no more than one of button, loginButton, inlineForm')
  }

  const withSmoothScroll =  useSmoothScroll();

  let control = (() => {
    if (inlineForm) {
      return <InlineLeadForm config={inlineForm}/>
    } else if (loginButton) {
      return <LoginButton blue={true} onLogin={loginButton.onLogin} analyticsPlacement={loginButton.eventLabel}/>
    } else if (button) {
      if (typeof button.href === 'string') {
        return <ActionButton label={button.label} href={button.href} size={buttonSize} onClick={() => {
          gtag.event({
            action: "internal_navigate",
            label: button.eventLabel,
            label2: "call_to_action"
          })
        }}/>
      } else {
        if (!button.eventName) {
          throw new Error('eventName has to be set for scroll button')
        }
        return <ActionButton label={button.label} size={buttonSize} onClick={() => {
          // @ts-ignore shitty TypeScript cannot check an interface type :(
          withSmoothScroll(() => button.href.current!.scrollIntoView());
          gtag.event({
            action: "internal_navigate",
            label: button.eventLabel,
            label2: `call_to_action_${button.eventName}`
          })
        }}/>
      }
    }
  })();


  return <Screen className={clss(styles.ctaScreen, clsIf(right, styles.reversed))} gray={gray} ref={ref}>
      <div className={clss(styles.contentCol)}>
        <TextBlock titleRegular={titleRegular} titleBold={titleBold}
                   slogan={slogan}
                   description={description}
                   hint={hint}
          />
      </div>
      <div className={clss(styles.backgroundContainer, clsIf(imageHiddenOnMobile, styles.hiddenOnMobile))}>{imageSrc && <>
          <ImageWithBlur src={imageSrc} draggable={false} alt={imageAlt} loading={"lazy"}
                         layout={"responsive"} objectFit={"cover"}/>
          {backgroundText && <div className={backgroundText.className}>
            {backgroundText.text}
          </div>}
        </>
      }</div>
    {children && <div className={styles.childrenContainer}>{children}</div>}
    {control && <div className={styles.buttonRow}>{control}</div>}

  </Screen>
});